import { useState, useRef } from 'react';
import Header from '../../components/Header';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Basemaps from '../../components/maps/Basemaps';
import SchoolMarker from '../../components/maps/SchoolMarker';
import { MapContainer, LayersControl, useMapEvents, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { phuThienCoord as phuThien } from '../../data/geoCoding';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const Pathfinder = () => {
  const mapRef = useRef();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { Overlay } = LayersControl;
  const [coordinates, setCoordinates] = useState([]);
  const timeoutRef = useRef(null);
  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          lat: school[2],
          long: school[3],
          address: school[4],
          ward: subarea.name,
          subarea_ID: subarea.id,
          district: area.name,
          district_id: area.id,
          city: city.name,
          city_id: city.id,
          startDate: school[8], // Initialize start and end dates
          endDate: school[9],
        }));
      })
    )
  );
  const MyComponent = () => {
    useMapEvents({
      click(e) {
        const currentCoords = [...coordinates, e.latlng];
        setCoordinates((prev) => [...prev, e.latlng]);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          alert(`${currentCoords.map((coord) => `[${coord.lat.toFixed(5)}, ${coord.lng.toFixed(5)}]`).join(', ')}`);
          setCoordinates([]); // Reset the coordinates after showing the alert
        }, 2000);
      },
    });
    return null;
  };

  return (
    <Box m='-53px 0 0 20px'>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title='PATHFINDER' subtitle='Plot your paths' />
      </Box>
      {/* GRID & CHARTS */}
      <Box fullwidth display={'flex'} alignItems={'center'} mb={'1em'} p={1} bgcolor={colors.greenAccent[700] + '33'}>
        <InfoIcon sx={{ mr: '0.5em' }} />
        <Typography fontSize={'16px'}>
          Bấm lên bản đồ để chọn các <b>điểm tạo nên đường polyline</b> đại diện cho một tuyến đường. Khi kết thúc, chờ 3 giây để nhận{' '}
          <b>toạ độ</b> của các điểm đã chọn.
        </Typography>
      </Box>
      <Box width='100%' height={'calc(100vh / 6 * 5)'} id='map'>
        <MapContainer
          center={phuThien.center}
          zoom={13}
          minZoom={10}
          maxZoom={18}
          maxBoundsViscosity={1.0}
          maxBounds={L.latLngBounds(phuThien.sW, phuThien.nE)}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
          }}>
          <LayersControl position='topright'>
            {schools.map((school, idx) => (
              <Overlay name={idx + 1 + ' - ' + school.schoolName} key={idx} checked>
                <SchoolMarker position={[school.lat, school.long]} address={school.address} name={school.schoolName} />
              </Overlay>
            ))}
            <Overlay name='Selected route' checked>
              <Polyline positions={coordinates} color='blue' pathOptions={{ color: 'blue', weight: 9 }} />
            </Overlay>
            <Basemaps />
          </LayersControl>
          <MyComponent />
        </MapContainer>
      </Box>
    </Box>
  );
};

export default Pathfinder;
