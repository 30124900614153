import React, { useEffect, useState } from 'react';
import { roads_11 as roads, route_to_11 as routes } from '../../data/geoCoding';
import {} from '../../data/geoCoding';
// import { Box, Typography, Button, useTheme } from '@mui/material';
import { Box } from '@mui/material';

import calculateBoundingRectangles from '../../components/calculateBoundingRectangles.js';
const Misc = () => {
  const newRoads = roads.map((road) => ({
    id: road.id,
    road: road.road,
    lat1: road.central[0][0],
    long1: road.central[0][1],
    lat2: road.central[1][0],
    long2: road.central[1][1],
    count: road.count,
    ratio: road.ratio,
    color: road.color,
  }));
  // Function to check if a point is inside a rectangle
  function isPointInRectangle(point, rectangleCorners) {
    const [lat, lon] = [point.Lat, point.Long];

    let inside = false;
    for (let i = 0, j = rectangleCorners.length - 1; i < rectangleCorners.length; j = i++) {
      const [latI, lonI] = rectangleCorners[i];
      const [latJ, lonJ] = rectangleCorners[j];

      const intersect = lonI > lon !== lonJ > lon && lat < ((latJ - latI) * (lon - lonI)) / (lonJ - lonI) + latI;
      if (intersect) inside = !inside;
    }

    return inside;
  }
  const width = 30; // 30 meters width
  // console.log(roads);

  // const isPointInBoundary = (point, boundary) => {
  //   const [topLeft, bottomRight] = boundary;
  //   return point[0] <= topLeft[0] && point[0] >= bottomRight[0] && point[1] >= topLeft[1] && point[1] <= bottomRight[1];
  // };

  // console.log(rectangle);
  const countIntersections = (routes, roads) => {
    const rectangles = calculateBoundingRectangles(roads, width);
    console.log('rectangles', rectangles);
    const intersectionCount = {};

    rectangles.forEach((rect) => {
      intersectionCount[rect.road] = {
        count: 0,
        routeIndices: [],
      }; // Initialize the count and array for each road

      Object.keys(routes).map((key, routeIndex) => {
        let hasIntersection = false;
        const route = routes[key];
        for (let i = 0; i < route.length; i++) {
          const point = route[i];
          // console.log(point);
          if (isPointInRectangle(point, rect.corners)) {
            hasIntersection = true;
            break; // Stop checking further points in this route if one intersects
          }
        }

        if (hasIntersection) {
          intersectionCount[rect.road].count += 1;
          intersectionCount[rect.road].routeIndices.push(routeIndex);
        }
      });
    });
    // console.log(intersectionCount);
    return intersectionCount;
  };
  const findRoadForPoints = (routes, roads) => {
    const results = [];

    const rectangle = calculateBoundingRectangles(roads, width);

    routes.forEach((route) => {
      const checkedRectangles = new Set();
      route.forEach((point, index) => {
        // roads.forEach((road) => {
        rectangle.forEach((rect) => {
          if (!checkedRectangles.has(rect.road)) {
            // Only check if not already found
            const isInside = isPointInRectangle(point, rect.corners);
            console.log(isInside);
            if (isInside) {
              results.push({ lat: point[0], long: point[1], route_number: index, road: rect.road });
              checkedRectangles.add(rect.road); // Add the rectangle to the set
            }
          }
        });
      });
    });
    console.log(results);
    return results;
  };

  const [results, setResults] = useState([]);
  // eslint-disable-next-line
  useEffect(() => {
    // console.log('routes', routes);
    // console.log('roads', roads);
    const _results = countIntersections(routes, newRoads);
    setResults(_results);
  }, []);

  const ranges = [
    { min: 0, max: 20 },
    { min: 21, max: 40 },
    { min: 41, max: 60 },
    { min: 61, max: 80 },
    { min: 81, max: 100 },
  ];
  const [selectedRanges, setSelectedRanges] = useState([]);

  const handleRangeChange = (rangeIndex) => {
    const updatedRanges = [...selectedRanges];
    if (updatedRanges.includes(rangeIndex)) {
      updatedRanges.splice(updatedRanges.indexOf(rangeIndex), 1);
    } else {
      updatedRanges.push(rangeIndex);
    }
    setSelectedRanges(updatedRanges);
  };

  const filteredData = roads.filter((roadSegment) => {
    return selectedRanges.some((rangeIndex) => {
      const { min, max } = ranges[rangeIndex];
      return roadSegment.ratio >= min && roadSegment.ratio <= max;
    });
  });

  function RoadSegment({ roadSegment }) {
    return (
      <div>
        <h2>Road Segment {roadSegment.id}</h2>
        <p>Road: {roadSegment.road}</p>
        {/* Render central and plot coordinates using a mapping function or a dedicated component */}
        <p>Count: {roadSegment.count}</p>
        <p>Ratio: {roadSegment.ratio}%</p>
      </div>
    );
  }

  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <div>
        <h1>Road Boundary Checker</h1>
        <p>
          Total routes: {Object.keys(routes).length} - Total roads: {newRoads.length}
        </p>
        <ol>
          {Object.keys(results).map((road) => (
            <li key={road}>
              <strong>{road}</strong>: {results[road].count} intersections. Ratio:{' '}
              {(results[road].count / Object.keys(routes).length) * 100} % [
              {results[road].routeIndices.map((index) => (
                <span key={index}>{index + 1}, </span>
              ))}
              ]
            </li>
          ))}
        </ol>
      </div>

      <div>
        {/* Checkbox list for selecting ranges */}
        <ul>
          <p>{selectedRanges}</p>
          {ranges.map((range, index) => (
            <li key={index}>
              <input type='checkbox' checked={selectedRanges.includes(index)} onChange={() => handleRangeChange(index)} />
              {`Range ${range.min}-${range.max}`}
            </li>
          ))}
        </ul>

        {/* Render filtered data */}
        <div>
          {filteredData.map((roadSegment, index) => (
            <RoadSegment key={index} roadSegment={roadSegment} />
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Misc;
