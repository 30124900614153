import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Fragment } from 'react';
import { densityColor } from '../data/geoCoding';
const ResultBarChart = ({ resultData, labels }) => {
  // console.log(resultData);
  // console.log(labels);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display='grid'
      gridTemplateColumns='repeat(12, 1fr)'
      gap={'5px'}
      rowGap={'8px'}
      width={'100%'}
      sx={{
        '& > *': {
          borderBottom: '1px solid ' + colors.grey[400], // Add bottom border to each item
          paddingBottom: '6px', // Add space between the content and border
        },
        '& > :nth-of-type(4n)': {
          borderBottom: 'none', // Remove bottom border from the last item in each row
        },
        borderTop: '1px solid ' + colors.grey[400],
        paddingTop: '12px',
      }}>
      <Box gridColumn='span 1' fontWeight={'bold'}>
        {labels.no}
      </Box>
      <Box gridColumn='span 4' textAlign={'right'} fontWeight={'bold'}>
        {labels.routes}
      </Box>
      <Box gridColumn='span 2' fontWeight={'bold'} textAlign={'right'} p={'0 6px'}>
        {labels.ratios}
      </Box>
      <Box gridColumn='span 5' fontWeight={'bold'}>
        {labels.results}
      </Box>

      {resultData.map((road, idx) => (
        <Fragment key={idx}>
          <Box gridColumn='span 1' textAlign={'center'} fontWeight={'bold'}>
            {idx + 1}
          </Box>
          <Box gridColumn='span 4' textAlign={'right'}>
            {road.road}
          </Box>
          <Box gridColumn='span 2' fontWeight={'bold'} textAlign={'right'} p={'0 6px'}>
            {road.ratio}
          </Box>
          <Box gridColumn='span 5' display={'flex'} alignItems='center'>
            <Box width={road.ratio + '%'} height={'8px'} borderRadius={'3px'} backgroundColor={densityColor[road.color - 1]}></Box>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};

export default ResultBarChart;
